import React from "react"
import { graphql } from "gatsby"
import useTranslations from "../hooks/useTranslations"
import { LocaleContext } from "../components/layout"
import ReactMarkdown from 'react-markdown'

export default function GtcPage(props) {
    const { locale } = React.useContext(LocaleContext)
    const { gtcPage } = useTranslations()
    return (
        <>
            <div className="mx-auto my-8 prose prose-stone prose-h1:text-als-red">
                <h1>{gtcPage.title}</h1>
                    {props.data.allGoogleAgbSheet.nodes.map(node => {
                        const title = locale === "de" ? node.titleDe : node.titleEn
                        const text = locale === "de" ? node.textDe : node.textEn
                        return (
                            <div className="grid grid-cols-[50px_minmax(200px,_1fr)]" key={node.id}>
                                <p>{title}</p>
                                <ReactMarkdown>{text}</ReactMarkdown>
                            </div>
                        )
                    })}
            </div>
        </>
    )
}

export const query = graphql`
  query GTC {
    allGoogleAgbSheet {
      nodes {
        id
        titleDe
        textDe
        titleEn
        textEn
      }
    }
  }
`